import React from "react"
import Layout from "../components/layout"


const getQueryParams = (qs) =>{
    qs = qs.split('+').join(' ');
    var params = {},
        tokens,
        re = /[?&]?([^=]+)=([^&]*)/g;

    while (tokens = re.exec(qs)) {
        params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }
    return params;
}

const LoginPage = (props) => {

    const p = {
            lang: 'default',
            blok : {},
            path : '/login'
    }
    if (typeof window !== "undefined" && document.location.search){
        var query = getQueryParams(document.location.search)
        if (query.error){
            localStorage.removeItem('authorization_token');
            localStorage.removeItem('refresh_token');
        }
        else {
            var authorization_token = query.authorization_token || '';
            var refresh_token = query.refresh_token || '';
            if(authorization_token) {
                localStorage.setItem('authorization_token', authorization_token);
            }
            if(refresh_token) {
                localStorage.setItem('refresh_token', refresh_token);
            }
            window.history.replaceState({authorization_token: ''}, 'welcome', '/welcome');
            if (query.redirect) {
                let isCorrectLink = false;

                if (/^\//.test(query.redirect) && !/^\/\//.test(query.redirect)) {
                    isCorrectLink = true;
                }

                // if redirect is absolute url, check if hasselblad domain
                if (/^https?:\/\//i.test(query.redirect)) {
                    try {
                        const link = new URL(query.redirect)
                        const hostname = link.hostname;
                        if (/hasselblad.com$/.test(hostname)) {
                            isCorrectLink = true;
                        }
                    } catch (ex) {
                    }
                }
                if(isCorrectLink){
                    window.location.href = query.redirect;
                } else {
                    window.location.href = "/";
                }
            }
        }
    }


    return (
        <Layout page={p}>
            <div className="editorial">
                <div>
                    <img src="/assets/icons/loading-indicator.svg" alt="Loading" />
                </div>
            </div>
        </Layout>
    )
}


export default LoginPage
